import Button from "react-bootstrap/Button";
import Image from "next/image";
import Layout from "../components/Layout";
import Navbar from "react-bootstrap/Navbar";
import { serverSideTranslations } from "next-i18next/serverSideTranslations";
import styles from "../styles/404.module.scss";
import { useRouter } from "next/router";
import { useTranslation } from "next-i18next";

const Custom404Page = () => {
  const router = useRouter();
  const { t } = useTranslation();

  const handleGoBack = () => {
    router.back();
  };
  return (
    <Layout variant={"dark"}>
      <Navbar
        fixed="top"
        bg="dark"
        variant="dark"
        className={styles["navbar-padding"]}
      >
        <Navbar.Brand
          className={styles["navbar-brand"]}
          onClick={() => router.push("/dashboard")}
        >
          <Image
            alt="logo-white"
            src="/logo-white-vertical.svg"
            width={107}
            height={42}
          />
        </Navbar.Brand>
      </Navbar>
      <div className="d-flex justify-content-center align-items-center bg-dark flex-fill flex-column">
        <div className={styles["inner-container"]}>
          <Image
            alt="404-img"
            src="/404-img.svg"
            width={355}
            height={350}
            priority
          />
          <div className={styles["message-container"]}>
            <p className={styles.message}>
              {t('views.notFound.messageFirst')}
            </p>
            <p className={styles.message}> {t('views.notFound.messageSecond')}</p>
          </div>
        </div>
        <Button
          variant="primary"
          onClick={handleGoBack}
          className={styles.button}
        >
          {t('general.goBack')}
        </Button>
      </div>
    </Layout>
  );
};

export async function getStaticProps (ctx) {
  return {
    props: {
      ...(await serverSideTranslations(ctx.locale, ["common"])),
    }
  }
}

export default Custom404Page;
